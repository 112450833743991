// Libs
import Icon from '@ant-design/icons';

const icon = () => (
  <svg
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_705_434)">
      <path
        d="M21.0688 0.46875H0.931185C0.674036 0.46875 0.465576 0.678617 0.465576 0.9375V14.0625C0.465576 14.3214 0.674036 14.5312 0.931185 14.5312H21.0688C21.3259 14.5312 21.5344 14.3214 21.5344 14.0625V0.9375C21.5344 0.678617 21.3259 0.46875 21.0688 0.46875Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5344 0.9375L11.291 9.27188C11.207 9.34326 11.1006 9.38242 10.9907 9.38242C10.8807 9.38242 10.7743 9.34326 10.6903 9.27188L0.465576 0.9375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1851 14.5312L13.4792 7.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.931152 14.5312L8.38089 7.73438"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_705_434">
        <rect width="22" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default (props) => <Icon component={icon} {...props} />;
