// Libs
import { Dropdown } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import CustomIcon from '@app/components/common/Icons/Index';

// Hooks
import useLanguage from '@app/hooks/useLanguage';

// Constants
import {
  LANGUAGE_CODE,
  LANGUAGE_COUNTRY_CODE,
  LANGUAGE_COUNTRY_CODE_MAPPING,
  SUPPORTED_LANGUAGE_COUNTRY_CODES,
} from '@app/constants';

// Styles
import './LanguageDropdown.scss';

const LanguageDropdown = ({ isHeader = false }) => {
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  // Events
  const changeLanguagePath = useCallback(
    (languageCountryCode) => {
      const paths = location.pathname.split('/');
      const pathname =
        location.pathname.indexOf(languageCountryCode) > -1
          ? location.pathname
          : SUPPORTED_LANGUAGE_COUNTRY_CODES.some(
              (l) => location.pathname.indexOf(l) > -1
            )
          ? location.pathname.replace(
              [
                '/',
                SUPPORTED_LANGUAGE_COUNTRY_CODES.find(
                  (l) => location.pathname.indexOf(l) > -1
                ),
              ].join(''),
              ['/', languageCountryCode].join('')
            )
          : paths.splice(paths.length, 0, languageCountryCode);
      navigate([pathname, location.search, location.hash].join(''));
    },
    [location, navigate]
  );
  const multiLanguages = useMemo(
    () => [
      {
        key: LANGUAGE_CODE.VIETNAMESE,
        label: <p>{t('common.header.right.language.vietnamese')}</p>,
        onClick: async () => {
          await setLanguage(LANGUAGE_CODE.VIETNAMESE);
          changeLanguagePath(
            LANGUAGE_COUNTRY_CODE_MAPPING[LANGUAGE_CODE.VIETNAMESE]
          );
        },
      },
      {
        key: LANGUAGE_CODE.ENGLISH,
        label: <p>{t('common.header.right.language.english')}</p>,
        onClick: async () => {
          await setLanguage(LANGUAGE_CODE.ENGLISH);
          changeLanguagePath(
            LANGUAGE_COUNTRY_CODE_MAPPING[LANGUAGE_CODE.ENGLISH]
          );
        },
      },
    ],
    [setLanguage, t]
  );

  useEffect(() => {
    if (
      SUPPORTED_LANGUAGE_COUNTRY_CODES.some(
        (l) => location.pathname.indexOf(l) > -1
      )
    ) {
      const languageCountryCode = SUPPORTED_LANGUAGE_COUNTRY_CODES.find(
        (l) => location.pathname.indexOf(l) > -1
      );
      setLanguage(
        languageCountryCode === LANGUAGE_COUNTRY_CODE.ENGLISH
          ? LANGUAGE_CODE.ENGLISH
          : languageCountryCode
      );
    }
  }, [setLanguage, location]);

  return (
    <div className={isHeader ? 'language' : 'language-dropdown'}>
      <CustomIcon name="language" />
      <Dropdown
        menu={{
          selectedKeys: [language],
          items: multiLanguages,
        }}
      >
        <div className="app-header-right__language">
          <p>{language === 'vi' ? 'VN' : 'EN'}</p>
        </div>
      </Dropdown>
      {!isHeader && <CustomIcon name="arrow-down-white" />}
    </div>
  );
};
export default LanguageDropdown;
