// Libs
import Icon from '@ant-design/icons';

const icon = () => (
  <svg viewBox="0 0 1062.99 708.661" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 0h1063v708.66h-1063z" fill="#ec0015" fillRule="evenodd" />
    <path
      transform="matrix(11.912 0 0 12.458 -2509.8 -6130.7)"
      d="m266.186,534.45-10.408-7.432-10.357,7.505 3.852-12.196-10.338-7.531 12.79-.105 3.967-12.159 4.052,12.131 12.79.016-10.285,7.602 3.937,12.169z"
      fill="#ff0"
      fillRule="evenodd"
    />
  </svg>
);

export default (props) => <Icon component={icon} {...props} />;
