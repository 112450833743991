// Libs
import {
  DefaultFooter,
  ProConfigProvider,
  ProLayout,
  nanoid,
} from '@ant-design/pro-components';
import {
  Avatar,
  Badge,
  Button,
  ConfigProvider,
  Divider,
  Dropdown,
  Input,
  List,
  Menu,
  Select,
  Space,
  theme,
} from 'antd';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiLockPasswordFill, RiLogoutBoxRFill } from 'react-icons/ri';
import { ImProfile } from 'react-icons/im';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import Cookies from 'js-cookie';
import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

// Assets
import USAFlag from '@app/components/common/Icons/USAFlag';
import VietnamFlag from '@app/components/common/Icons/VietnamFlag';
import Bell from '@app/components/common/Admin/Icons/Bell';
import Letter from '@app/components/common/Admin/Icons/Letter';

// Settings
import defaultSettings from './_defaultSettings';
import defaultProps from './_defaultProps';
import { getRoute } from './routes';

// Styles
import './AdminLayout.scss';

// Components
import CustomIcon from '@app/components/common/Icons/Index';

// Hooks
import useLanguage, { getLocalByLanguageCode } from '@app/hooks/useLanguage';

// Constants
import { COOKIES, DATE_FORMAT, LANGUAGE_CODE, ROUTES } from '@app/constants';

// Reducers
import { useLazyGetPropertiesByMemberIdQuery } from '@app/slices/propertiesSlice';

// Common variables
const cursorStyle = {
  cursor: 'pointer',
};

export default () => {
  // Using
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const [collapsed, setCollapsed] = useState(true);
  const [titlePage, setTitlePage] = useState('');
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const authUserData = useAuthUser();
  const user = authUserData();
  const [getPropertiesByMemberId] = useLazyGetPropertiesByMemberIdQuery();
  const [properties, setProperties] = useState([]);
  const [propertiesFilter, setPropertiesFilter] = useState([]);
  const propertySelectRef = useRef(null);
  const [propertySelected, setPropertySelected] = useState(null);

  useEffect(() => {
    const getNameTilePage = () => {
      const titleNames = pathname.split('/');

      return titleNames
        .filter((name) => {
          return t(`pages.${name}.title`).includes('.') === false;
        })
        .map((name) => {
          return t(`pages.${name}.title`);
        })
        .join('-');
    };

    setTitlePage(getNameTilePage() || t('pages.home.title'));
  }, [pathname]);

  useEffect(() => {
    const getProperties = async () => {
      const { data: response } = await getPropertiesByMemberId({
        memberId: user.uid,
      });
      if (!response || !response.success) {
        setProperties([]);
        setPropertiesFilter([]);
        return;
      }

      const propertiesMap = ((response && response.properties) || []).map(
        (property) => ({
          key: property.id,
          label: (
            <Space>
              <CustomIcon name="property-white" />
              <Divider type="vertical" className="property-selector-divider" />
              {property.title}
            </Space>
          ),
          icon: null,
          value: property.id,
          title: property.title,
          class: property.propertyClass,
          createAt: property.createAt,
        })
      );
      setProperties(propertiesMap);
      setPropertiesFilter(propertiesMap);
      const pr = (response && response.properties) || [];
      const propertyIdSelected =
        Cookies.get(COOKIES.PROPERTY_ID) || (pr.length > 0 ? pr[0].id : null);
      setPropertySelected(propertyIdSelected);
      Cookies.set(COOKIES.PROPERTY_ID, propertyIdSelected);
    };

    getProperties();
  }, [setProperties, user]);

  const multiLanguages = useMemo(
    () => [
      {
        key: LANGUAGE_CODE.VIETNAMESE,
        label: <>{t('common.header.right.language.vietnamese')}</>,
        icon: <VietnamFlag style={{ width: '20px' }} />,
        onClick: () => setLanguage(LANGUAGE_CODE.VIETNAMESE),
      },
      {
        key: LANGUAGE_CODE.ENGLISH,
        label: <>{t('common.header.right.language.english')}</>,
        icon: <USAFlag style={{ width: '20px' }} />,
        onClick: () => setLanguage(LANGUAGE_CODE.ENGLISH),
      },
    ],
    [setLanguage, t]
  );

  if (typeof document === 'undefined') {
    return <div />;
  }

  // Memos
  const userLoginOptions = useMemo(() => [
    {
      key: nanoid(),
      label: (
        <Space>
          {!!user && user.avatar && <Avatar src={user.avatar} size="default" />}
          <span>
            {t('admin.header.welcome', {
              name: !!user && !!user.name && user.name.toUpperCase(),
            })}
          </span>
        </Space>
      ),
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          className="language"
          rootClassName="language-select"
          defaultSelectedKeys={[language]}
          items={[
            {
              key: nanoid(),
              label: t('admin.language.' + language),
              children: [
                {
                  key: LANGUAGE_CODE.ENGLISH,
                  label: t('admin.language.en'),
                  icon: <USAFlag style={{ width: '20px' }} />,
                  onClick: () => setLanguage(LANGUAGE_CODE.ENGLISH),
                },
                {
                  key: LANGUAGE_CODE.VIETNAMESE,
                  label: t('admin.language.vi'),
                  icon: <VietnamFlag style={{ width: '20px' }} />,
                  onClick: () => setLanguage(LANGUAGE_CODE.VIETNAMESE),
                },
                // {
                //   key: LANGUAGE_CODE.JAPANESE,
                //   label: t('admin.language.ja'),
                //   onClick: () => setLanguage(LANGUAGE_CODE.JAPANESE),
                // },
              ],
              expandIcon: <MdOutlineKeyboardArrowRight />,
            },
          ]}
        />
      ),
      icon:
        language === LANGUAGE_CODE.ENGLISH ? (
          <USAFlag style={{ width: '20px' }} />
        ) : (
          <VietnamFlag style={{ width: '20px' }} />
        ),
      danger: false,
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          items={[
            {
              key: nanoid(),
              label: t('admin.profile'),
              onClick: () => {
                setPathname('/member/profile');
                navigate('/member/profile');
              },
            },
          ]}
        />
      ),
      icon: <ImProfile size="20" />,
      danger: false,
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          items={[
            {
              key: nanoid(),
              label: t('admin.change-password'),
            },
          ]}
        />
      ),
      icon: <RiLockPasswordFill size="20" />,
      danger: false,
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          items={[
            {
              key: nanoid(),
              label: t('admin.logout'),
              onClick: () => signOut(),
            },
          ]}
        />
      ),
      icon: <RiLogoutBoxRFill size="20" />,
      danger: true,
    },
  ]);

  // Events
  const handleSearchProperty = useCallback(
    (e) => {
      const searchValue = (
        (e && e.target && e.target.value) ||
        ''
      ).toLowerCase();
      setPropertiesFilter(
        properties.filter(
          (property) =>
            !searchValue ||
            property.title.toLowerCase().indexOf(searchValue) > -1
        )
      );
    },
    [properties, setProperties]
  );

  // Rendering
  return (
    <div id="admin-layout">
      <ProConfigProvider>
        <ConfigProvider
          locale={getLocalByLanguageCode(language)}
          getTargetContainer={() => {
            return document.getElementById('admin-layout') || document.body;
          }}
          theme={{
            algorithm: theme.defaultAlgorithm,
            components: {
              Menu: {
                groupTitleColor: '#4B5254',
              },
              Layout: {
                footerBg: '#FFFFFF',
                bodyBg: '#FFFFFF',
              },
            },
          }}
        >
          <ProLayout
            {...defaultProps}
            {...defaultSettings({ title: titlePage }, setPathname)}
            avatarProps={{
              src: !!user && user.avatar,
              size: 'small',
              title: (!!user && !!user.name && user.name.toUpperCase()) || '',
              render: (_props, defaultDom) => (
                <Dropdown
                  trigger={['click']}
                  className="avatar"
                  rootClassName="root-avatar"
                  arrow
                  menu={{
                    items: userLoginOptions,
                    className: 'menu-avatar',
                  }}
                  icon={<MdOutlineKeyboardArrowDown />}
                >
                  <Space>
                    {defaultDom}
                    <MdOutlineKeyboardArrowDown />
                  </Space>
                </Dropdown>
              ),
            }}
            route={getRoute(t)}
            menuItemRender={(item, dom) =>
              !item.routes || item.routes.length === 0 ? (
                <div
                  onClick={() => {
                    setPathname(item.path || ROUTES.HOME);
                    navigate(item.path || ROUTES.HOME);
                  }}
                  style={{
                    ...cursorStyle,
                  }}
                >
                  {dom}
                </div>
              ) : (
                <div>{dom}</div>
              )
            }
            collapsed={collapsed}
            onCollapse={setCollapsed}
            location={{
              pathname,
            }}
            actionsRender={(props) => {
              if (props.isMobile) return [];
              if (typeof window === 'undefined') return [];
              return [
                <>
                  <div className="property-selector">
                    <Select
                      popupMatchSelectWidth={false}
                      showSearch={false}
                      suffixIcon={<CustomIcon name="up-down" />}
                      ref={propertySelectRef}
                      listHeight={350}
                      listItemHeight={30}
                      value={propertySelected}
                      options={propertiesFilter}
                      placement="bottomLeft"
                      menuItemSelectedIcon={null}
                      defaultValue={propertySelected}
                      rootClassName="property-root"
                      dropdownRender={(menu) => {
                        return (
                          <>
                            <Space direction="horizontal">
                              <Input
                                placeholder=""
                                prefix={<SearchOutlined />}
                                allowClear
                                suffix={null}
                                onChange={handleSearchProperty}
                                className="property-selector-search"
                              />
                              <Button
                                type="primary"
                                className="btn-bbk-primary"
                              >
                                <Space>
                                  {t('common.button.add')}
                                  <CustomIcon name="circle-plus" />
                                </Space>
                              </Button>
                            </Space>
                            <Divider
                              style={{
                                margin: '8px auto',
                              }}
                            />
                            <List rootClassName="property-list">{menu}</List>
                          </>
                        );
                      }}
                      optionRender={(option, info) => {
                        return (
                          <List.Item
                            onMouseDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onMouseEnter={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            actions={[
                              <Space direction="vertical" size="small">
                                <span>Owner: Administrator</span>
                                <span>Location: VN</span>
                              </Space>,
                              <Space direction="vertical" size="small">
                                <Button
                                  className="btn-bbk-primary"
                                  type={
                                    propertySelected === option.value
                                      ? 'primary'
                                      : 'default'
                                  }
                                  onClick={() => {
                                    if (propertySelected === option.value) {
                                      return;
                                    }
                                    setPropertySelected(option.value);
                                    Cookies.set(
                                      COOKIES.PROPERTY_ID,
                                      option.value
                                    );
                                  }}
                                >
                                  {t('common.button.select')}
                                </Button>
                                <Select
                                  options={[
                                    {
                                      label: 'Active',
                                      value: 1,
                                      key: 'active',
                                    },
                                    {
                                      label: 'Edit',
                                      value: 2,
                                      key: 'edit',
                                    },
                                  ]}
                                  placeholder={t('common.action')}
                                  style={{
                                    width: '100%',
                                  }}
                                  popupMatchSelectWidth={false}
                                />
                              </Space>,
                            ]}
                          >
                            <List.Item.Meta
                              className="property-list-meta"
                              avatar={
                                <Badge
                                  count={info.index + 1}
                                  className="property-list-count"
                                  size="default"
                                  color="#125AAF"
                                >
                                  <CustomIcon name="property-black" />
                                </Badge>
                              }
                              title={option.data.title}
                              description={
                                <Space direction="vertical" size="small">
                                  <span>
                                    {t('common.header.create-date', {
                                      date: dayjs(option.data.createAt).format(
                                        DATE_FORMAT.DEFAULT
                                      ),
                                    })}
                                  </span>
                                  <span>
                                    {'******'.substring(
                                      0,
                                      option.data.class || 0
                                    )}
                                  </span>
                                </Space>
                              }
                            />
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                </>,
                <>
                  <div className="language">
                    <Dropdown
                      menu={{
                        defaultSelectedKeys: [language],
                        selectedKeys: [language],
                        items: multiLanguages,
                      }}
                      rootClassName="language-root"
                    >
                      <Space>
                        <CustomIcon name="language" />
                        <p>
                          {language === LANGUAGE_CODE.VIETNAMESE ? 'VN' : 'EN'}
                        </p>
                      </Space>
                    </Dropdown>
                  </div>
                </>,
                <Bell key="Bell" />,
                <Letter key="Letter" />,
              ];
            }}
            headerContentRender={(props, dom) => {
              if (props.isMobile) {
                return (
                  <>
                    <Link
                      to={ROUTES.HOME}
                      onClick={() => setPathname(ROUTES.HOME)}
                      className="logo-sp"
                    >
                      <span className="yellow title">Sapphire</span>
                      <span className="title">Booking</span>
                    </Link>
                  </>
                );
              }

              return <>{dom}</>;
            }}
            headerTitleRender={() => (
              <>
                <Link to={ROUTES.HOME} onClick={() => setPathname(ROUTES.HOME)}>
                  <MenuOutlined
                    style={{ color: '#fff' }}
                    className="menu-icon"
                  />

                  <span className="yellow title">Sapphire</span>
                  <span className="title">Booking</span>
                </Link>
              </>
            )}
            footerRender={() => (
              <DefaultFooter
                className="admin-footer"
                copyright={t('admin.footer.copyright')}
              />
            )}
          >
            <Outlet />
          </ProLayout>
        </ConfigProvider>
      </ProConfigProvider>
    </div>
  );
};
