export const LOCAL_STORAGE = {
  LANGUAGE: 'LANGUAGE',
};

export const LANGUAGE_CODE = {
  ENGLISH: 'en',
  VIETNAMESE: 'vi',
  JAPANESE: 'ja',
  CHINESE: 'cn',
};

export const LANGUAGE_COUNTRY_CODE = {
  ENGLISH: 'enUS',
  VIETNAMESE: 'vi',
  JAPANESE: 'ja',
  CHINESE: 'cn',
};

export const SUPPORTED_LANGUAGE_COUNTRY_CODES = [
  LANGUAGE_COUNTRY_CODE.VIETNAMESE,
  LANGUAGE_COUNTRY_CODE.ENGLISH,
  LANGUAGE_COUNTRY_CODE.JAPANESE,
  LANGUAGE_COUNTRY_CODE.CHINESE,
];

export const LANGUAGE_COUNTRY_CODE_MAPPING = {
  [LANGUAGE_CODE.ENGLISH]: LANGUAGE_COUNTRY_CODE.ENGLISH,
  [LANGUAGE_CODE.VIETNAMESE]: LANGUAGE_COUNTRY_CODE.VIETNAMESE,
  [LANGUAGE_CODE.JAPANESE]: LANGUAGE_COUNTRY_CODE.JAPANESE,
  [LANGUAGE_CODE.CHINESE]: LANGUAGE_COUNTRY_CODE.CHINESE,
};

export const DEFAULT_CURRENCY_CODE = 'VND';

export const PRODUCT = {
  NAME: 'SapphireBooking',
};

export const TOKEN = {
  TYPE: 'Bearer',
};

export const CONTENT_TYPE = {
  JSON: 'application/json',
};

export const PATH_NAME = {
  PROFILE: {
    INDEX: 'profile',
  },
  MEMBER: {
    INDEX: 'member',
    LOGIN: 'login',
    SIGN_UP: 'sign-up',
    VERIFY: 'verify',
    REGISTER: 'register',
    CONFIRMATION: 'confirmation',
    CONFIRMATION_SUCCESS: 'confirmation-success',
    CONFIRMATION_FAILURE: 'confirmation-failure',
  },
  PROPERTY: {
    INDEX: 'property',
    REGISTER: 'register',
  },
  HOME: '',
  MAIN: 'main',
  SYSTEM: 'systems',
  RATE_AND_AVAILABILITY: 'rate-and-availability',
  CALENDAR: 'calendar',
  USERS: 'users',
  RESERVATIONS: 'reservations',
  REPORTS: 'reports',
  CHANNELS: 'channels',
  PROMOTIONS: 'promotions',
  PANDEMIC_SEASON_INCENTIVES: 'pandemic-season-incentives',
  ADD_NEW_PROMOTION: 'add-new',
  REVIEWS: 'reviews',
  MEMBERS: 'members',
  SETTINGS: 'settings',
  NOT_FOUND: 'not-found',
  APIS: 'apis',
  SETUP: 'setup',
};

export const ROUTES = {
  HOME: ['', PATH_NAME.HOME].join('/'),
  MAIN: {
    INDEX: [PATH_NAME.HOME, PATH_NAME.MAIN].join('/'),
    CALENDAR: [
      PATH_NAME.HOME,
      PATH_NAME.RATE_AND_AVAILABILITY,
      PATH_NAME.CALENDAR,
    ].join('/'),
    RATE_AND_AVAILABILITY: [
      PATH_NAME.HOME,
      PATH_NAME.RATE_AND_AVAILABILITY,
    ].join('/'),
    USERS: [PATH_NAME.HOME, PATH_NAME.USERS].join('/'),
    RESERVATIONS: [PATH_NAME.HOME, PATH_NAME.RESERVATIONS].join('/'),
    REPORTS: [PATH_NAME.HOME, PATH_NAME.REPORTS].join('/'),
    CHANNELS: [PATH_NAME.HOME, PATH_NAME.CHANNELS].join('/'),
    PROMOTIONS: [PATH_NAME.HOME, PATH_NAME.PROMOTIONS].join('/'),
    PANDEMIC_SEASON_INCENTIVES: [
      PATH_NAME.HOME,
      PATH_NAME.PANDEMIC_SEASON_INCENTIVES,
    ].join('/'),
    ADD_NEW_PROMOTION: [
      PATH_NAME.HOME,
      PATH_NAME.PROMOTIONS,
      PATH_NAME.PANDEMIC_SEASON_INCENTIVES,
      PATH_NAME.ADD_NEW_PROMOTION,
    ].join('/'),
    REVIEWS: [PATH_NAME.HOME, PATH_NAME.REVIEWS].join('/'),
    SETTINGS: [PATH_NAME.HOME, PATH_NAME.SETTINGS].join('/'),
  },
  SYSTEM: {
    INDEX: [PATH_NAME.HOME, PATH_NAME.SYSTEM].join('/'),
    MEMBERS: [PATH_NAME.HOME, PATH_NAME.MEMBERS].join('/'),
    SETTINGS: [PATH_NAME.HOME, PATH_NAME.SETTINGS].join('/'),
    APIS: [PATH_NAME.HOME, PATH_NAME.APIS].join('/'),
  },
  MEMBER: {
    LOGIN: [
      PATH_NAME.HOME,
      PATH_NAME.MEMBER.INDEX,
      PATH_NAME.MEMBER.LOGIN,
    ].join('/'),
    SIGN_UP: [
      PATH_NAME.HOME,
      PATH_NAME.MEMBER.INDEX,
      PATH_NAME.MEMBER.SIGN_UP,
    ].join('/'),
    VERIFY: [
      PATH_NAME.HOME,
      PATH_NAME.MEMBER.INDEX,
      PATH_NAME.MEMBER.VERIFY,
    ].join('/'),
    CONFIRMATION: {
      SUCCESS: [
        PATH_NAME.HOME,
        PATH_NAME.MEMBER.INDEX,
        PATH_NAME.MEMBER.CONFIRMATION_SUCCESS,
      ].join('/'),
      FAILURE: [
        PATH_NAME.HOME,
        PATH_NAME.MEMBER.INDEX,
        PATH_NAME.MEMBER.CONFIRMATION_FAILURE,
      ].join('/'),
    },
  },
  PROPERTY: {
    SETUP: [
      PATH_NAME.HOME,
      PATH_NAME.PROPERTY.INDEX,
      PATH_NAME.PROPERTY.REGISTER,
    ].join('/'),
  },
  NOT_FOUND: [PATH_NAME.HOME, PATH_NAME.NOT_FOUND].join('/'),
};

export const COOKIES = {
  AUTH: '_auth_admin',
  PROPERTY_ID: '_propertyId',
};

export const STORAGE = {
  PROPERTY: 'property',
  LANGUAGE: 'language',
};

export const DATE_FORMAT = {
  DEFAULT: 'DD/MM/YYYY',
};
