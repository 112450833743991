import { MenuOutlined } from '@ant-design/icons';

export default (props) => {
  return {
    layout: 'top',
    title: (
      <>
        <span className="yellow title">Sapphire</span>
        <span className="title">Booking</span>
      </>
    ),
    // contentWidth: 'Fluid',
    splitMenus: true,
    //   loading: true,
    siderMenuType: 'group',
    logo: <MenuOutlined style={{ color: '#fff' }} className="menu-icon" />,
    prefixCls: 'sapphirebooking-setup',
    collapsedButtonRender: false,
    menu: {
      collapsedShowGroupTitle: false,
    },
    fixSiderbar: true,
    pageTitleRender: () => props.title + ' | SapphireBooking',
  };
};
