// Libs

// Constants
import { PATH_NAME, ROUTES } from '@app/constants';

// Assets
import ReservationIcon from '@app/components/common/Admin/Icons/Reservation';
import PromotionIcon from '@app/components/common/Admin/Icons/Promotion';
import ReviewIcon from '@app/components/common/Admin/Icons/Review';
import Dashboard from '@app/components/common/Admin/Icons/Dashboard';
import RatesAndAvailability from '@app/components/common/Admin/Icons/RatesAndAvailability';
import Chart from '@app/components/common/Admin/Icons/Chart';
import SettingIcon from '@app/components/common/Admin/Icons/Setting';

export const getRoute = (t) => {
  return {
    path: PATH_NAME.HOME,
    routes: [
      {
        name: t('admin.sider.main-navigation.dash-board'),
        path: ROUTES.HOME,
        icon: <Dashboard />,
      },
      {
        name: t('admin.sider.main-navigation.rates-and-availability'),
        path: ROUTES.MAIN.RATE_AND_AVAILABILITY,
        icon: <RatesAndAvailability />,
      },
      {
        name: t('admin.sider.main-navigation.promotions'),
        path: ROUTES.MAIN.PROMOTIONS,
        icon: <PromotionIcon />,
      },
      {
        name: t('admin.sider.main-navigation.reservations'),
        path: ROUTES.MAIN.RESERVATIONS,
        icon: <ReservationIcon />,
      },
      {
        name: t('admin.sider.main-navigation.reviews'),
        path: ROUTES.MAIN.REVIEWS,
        icon: <ReviewIcon />,
      },
      {
        name: t('admin.sider.main-navigation.reports'),
        path: ROUTES.MAIN.REPORTS,
        icon: <Chart />,
      },
      {
        name: t('admin.sider.main-navigation.settings'),
        path: ROUTES.MAIN.SETTINGS,
        icon: <SettingIcon />,
      },
    ],
  };
};
