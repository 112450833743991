// Libs
import Icon from '@ant-design/icons';

const icon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.7906 31.091H2.20963C0.990115 31.091 0.0270996 30.1055 0.0270996 28.9084V5.77358C0.0270996 4.54956 1.01261 3.58655 2.20963 3.58655H29.7906C31.0146 3.58655 31.9731 4.57656 31.9731 5.77358V28.9084C32.0001 30.1055 31.0146 31.091 29.7906 31.091ZM2.20963 4.47306C1.50762 4.47306 0.936114 5.04457 0.936114 5.74658V28.8814C0.936114 29.5835 1.50762 30.155 2.20963 30.155H29.7906C30.4926 30.155 31.0641 29.5835 31.0641 28.8814V5.74658C31.0641 5.04457 30.4926 4.47306 29.7906 4.47306H2.20963Z"
      fill="#1E79E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.532 10.9712H0.468007C0.207003 10.9712 0 10.7642 0 10.5032C0 10.2422 0.207003 10.0352 0.468007 10.0352H31.532C31.793 10.0352 32 10.2422 32 10.5032C32 10.7642 31.793 10.9712 31.532 10.9712Z"
      fill="#1E79E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8 8.0326C24.539 8.0326 24.332 7.8256 24.332 7.5646C24.332 7.30359 24.539 7.09659 24.8 7.09659C26.5145 7.09659 27.8915 5.69257 27.8915 4.00504C27.8915 2.31301 26.5145 0.935992 24.8 0.935992C23.0855 0.935992 21.7084 2.34001 21.7084 4.02754C21.7084 4.28854 21.4969 4.49555 21.2404 4.49555C20.9794 4.49555 20.7949 4.26154 20.7949 4.02754C20.7949 1.81801 22.5904 0.0269775 24.8 0.0269775C27.0095 0.0269775 28.805 1.81801 28.805 4.02754C28.805 6.23707 27.0095 8.0326 24.8 8.0326Z"
      fill="#1E79E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0112 8.03263C15.7547 8.03263 15.5432 7.82562 15.5432 7.56462C15.5432 7.30361 15.7547 7.09661 16.0112 7.09661C17.7302 7.09661 19.1073 5.69259 19.1073 4.00506C19.1073 2.31304 17.7032 0.909014 16.0112 0.909014C14.3237 0.909014 12.9197 2.31304 12.9197 4.00506C12.9197 4.26157 12.7127 4.47307 12.4516 4.47307C12.1906 4.47307 11.9836 4.26157 11.9836 4.00506C11.9836 1.79553 13.7792 0 15.9887 0C18.1982 0 20.0163 1.81803 20.0163 4.02756C20.0163 6.2371 18.2207 8.03263 16.0112 8.03263Z"
      fill="#1E79E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22717 8.0326C6.96617 8.0326 6.75916 7.8256 6.75916 7.5646C6.75916 7.30359 6.96617 7.12359 7.22717 7.12359C8.9417 7.12359 10.3187 5.71957 10.3187 4.02754C10.3187 2.34001 8.9147 0.935992 7.22717 0.935992C5.53514 0.935992 4.10862 2.31301 4.10862 4.02754C4.10862 4.28854 3.90162 4.49555 3.64061 4.49555C3.37961 4.49555 3.17261 4.28854 3.17261 4.02754C3.17261 1.81801 4.96364 0.0269775 7.17317 0.0269775C9.3827 0.0269775 11.1782 1.81801 11.1782 4.02754C11.1782 6.23707 9.43671 8.0326 7.22717 8.0326Z"
      fill="#1E79E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0952 27.4279H9.93155C8.86503 27.4279 7.97852 26.5414 7.97852 25.4749V15.6738C7.97852 14.6117 8.86503 13.7252 9.93155 13.7252H22.0952C23.1618 13.7252 24.0438 14.6117 24.0438 15.6738V25.4749C24.0438 26.5414 23.1618 27.4279 22.0952 27.4279ZM9.93155 14.6117C9.36004 14.6117 8.89203 15.0797 8.89203 15.6512V25.4479C8.89203 26.0239 9.36004 26.4919 9.93155 26.4919H22.0952C22.6667 26.4919 23.1348 26.0239 23.1348 25.4479V15.6738C23.1348 15.1022 22.6667 14.6342 22.0952 14.6342H9.93155V14.6117Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1437 24.5929C14.0357 24.5929 13.9097 24.5389 13.8287 24.4624L10.3996 21.0289C10.2151 20.8489 10.2151 20.5609 10.3996 20.3809C10.5796 20.2009 10.8676 20.2009 11.0476 20.3809L14.1437 23.4994L20.9793 16.6638C21.1593 16.4838 21.4473 16.4838 21.6273 16.6638C21.8118 16.8438 21.8118 17.1318 21.6273 17.3118L14.4542 24.4624C14.3777 24.5389 14.2472 24.5929 14.1437 24.5929Z"
      fill="white"
    />
  </svg>
);

export default (props) => <Icon component={icon} {...props} />;
