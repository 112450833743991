export default {
  MEMBER: {
    LOGIN: '/admin/members/login',
    SIGN_UP: '/members/register',
    VALIDATE_SIGN_UP_TOKEN: '/members/validateSignUpToken',
  },
  PROPERTY: {
    GET_PROPERTIES_BY_MEMBER_ID: '/admin/properties/getPropertiesByMemberId',
  },
};
