// Reducers
import apiSlice from './apiSlice';

// Constants
import API_ENDPOINTS from '@app/constants/apiEndpoints';

export const propertiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPropertiesByMemberId: builder.query({
      query: (arg) => ({
        url: API_ENDPOINTS.PROPERTY.GET_PROPERTIES_BY_MEMBER_ID,
        params: { ...arg },
      }),
      invalidatesTags: ['Property'],
    }),
  }),
});

export const { useLazyGetPropertiesByMemberIdQuery } = propertiesApiSlice;
