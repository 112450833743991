// Libs
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

// Constants
import { COOKIES, TOKEN } from '@app/constants';

const { REACT_APP_BACKEND_PATH } = process.env;
const accessToken = Cookies.get(COOKIES.AUTH);
const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BACKEND_PATH,
  prepareHeaders: (headers) => {
    headers.set(
      'Authorization',
      accessToken ? [TOKEN.TYPE, accessToken].join(' ') : undefined
    );
    headers.set('Content-Type', 'application/json');
  },
  validateStatus: (response /*, result*/) => response.status === 200, // && result.success,
});

export default createApi({
  baseQuery,
  tagTypes: ['Member', 'Property'],
  // eslint-disable-next-line no-unused-vars
  endpoints: (builder) => ({}),
});
