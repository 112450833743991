const RatesAndAvailability = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_705_660)">
      <path
        d="M15.1613 22.8367L16.323 21.6742L14.2147 19.5652L13.053 20.7292C12.3765 20.2807 11.622 19.947 10.8158 19.7377V18H7.07325V19.7377C6.249 19.9507 5.47725 20.2943 4.7895 20.757L3.67425 19.6417L1.566 21.7493L2.7 22.8832C2.26575 23.5477 1.941 24.2872 1.737 25.0755H0V28.8142H1.73625C1.9455 29.6212 2.28 30.378 2.72775 31.0545L1.56525 32.217L3.6735 34.3237L4.836 33.162C5.514 33.6112 6.2715 33.9465 7.08075 34.155V35.889H10.8083V34.155C11.5988 33.9517 12.3397 33.624 13.0057 33.1905L14.2147 34.3988L16.323 32.2912L15.1327 31.0995C15.5977 30.408 15.9428 29.631 16.155 28.803H17.889V25.0875H16.155C15.9473 24.2768 15.612 23.5162 15.1613 22.8367ZM8.9445 30.0112C7.251 30.0112 5.8785 28.6387 5.8785 26.9452C5.8785 25.2517 7.25175 23.8785 8.9445 23.8785C10.6372 23.8785 12.0105 25.2517 12.0105 26.9452C12.0105 28.6387 10.638 30.0112 8.9445 30.0112Z"
        fill="white"
      />
      <path
        d="M22.3156 24.8453C21.2844 24.8453 20.3484 24.5115 19.6801 24.1448L19.2039 26.004C19.8076 26.3528 20.8389 26.6385 21.9024 26.6865V28.1955H23.4594V26.5755C25.2864 26.259 26.2854 25.0508 26.2854 23.637C26.2854 22.209 25.5234 21.336 23.6341 20.6693C22.2849 20.1593 21.7299 19.8278 21.7299 19.3035C21.7299 18.8588 22.0621 18.414 23.0934 18.414C24.2371 18.414 24.9684 18.7793 25.3801 18.954L25.8414 17.16C25.3171 16.9058 24.6024 16.6838 23.5389 16.6358V15.2385H21.9834V16.7468C20.2839 17.0805 19.2999 18.1763 19.2999 19.5728C19.2999 21.1133 20.4586 21.9068 22.1581 22.4798C23.3326 22.8765 23.8411 23.2575 23.8411 23.8613C23.8404 24.495 23.2209 24.8453 22.3156 24.8453Z"
        fill="white"
      />
      <path
        d="M35.2237 18.3083L35.2365 18.303C35.2365 18.303 35.1562 18.051 34.9694 17.6138C34.9559 17.5837 34.9432 17.5545 34.9297 17.5252C34.8075 17.244 34.6432 16.8975 34.431 16.4888C34.4107 16.4513 34.3905 16.4145 34.3702 16.3778C33.1814 14.1368 30.567 10.2945 25.3792 6.77025L30 0H15.9L20.0737 6.82425C18.255 8.15175 13.6642 11.7697 11.325 16.0005H12.468H14.5957C17.4412 12.3525 22.5 7.78125 22.5 7.78125C22.5 7.78125 32.7502 15.1875 33.1252 22.4377C33.156 24.0315 32.9062 30.5632 24.4372 32.9692C23.0625 33.1875 21 33.3435 18.8122 32.6722V35.3872C20.0557 35.7765 21.372 36 22.7445 36C30.0652 36 36 30.0653 36 22.7445C36 21.186 35.7172 19.6973 35.2237 18.3083Z"
        fill="#3366CC"
      />
    </g>
    <defs>
      <clipPath id="clip0_705_660">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RatesAndAvailability;
