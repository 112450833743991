// Libs
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import enUS from 'antd/lib/locale/en_US';
import viVN from 'antd/lib/locale/vi_VN';
import jaJP from 'antd/lib/locale/ja_JP';

// Hooks
import { useLocalStorage } from '@app/hooks/useLocalStorage';

// Constants
import { LANGUAGE_CODE, STORAGE } from '@app/constants';
import { Dates } from '@app/constants/Dates';

export default () => {
  const [localLanguage, setLocalLanguage] = useLocalStorage(STORAGE.LANGUAGE);
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    async (locale) => {
      Dates.setLocale(locale);
      setLocalLanguage(locale);
      await i18n.changeLanguage(locale);
    },
    [i18n]
  );

  useEffect(() => {
    localLanguage && handleChangeLanguage(localLanguage);
  }, [handleChangeLanguage, localLanguage]);

  return useMemo(
    () => ({
      language: i18n.language,
      setLanguage: handleChangeLanguage,
    }),
    [handleChangeLanguage, i18n.language]
  );
};

export const getLocalByLanguageCode = (languageCode) => {
  switch (languageCode) {
    case LANGUAGE_CODE.ENGLISH:
      return enUS;
    case LANGUAGE_CODE.JAPANESE:
      return jaJP;
    default:
      return viVN;
  }
};

// Get current language and handleChange Language
