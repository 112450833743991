// Libs
import { useEffect, useState } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

// Constants
import { ROUTES } from '@app/constants';

export default () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [isNavigation, setIsNavigation] = useState(false);

  useEffect(() => {
    if (isNavigation) {
      return;
    }

    if (isAuthenticated()) {
      navigate(ROUTES.HOME);
      setIsNavigation(true);
      return;
    }
  }, [isAuthenticated, navigate, isNavigation, setIsNavigation]);
};
