export default () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8125 17.3671H16.6458V9.49994C16.6458 9.38184 16.5989 9.26856 16.5154 9.18505C16.4319 9.10153 16.3186 9.05463 16.2005 9.05463H13.585V3.85932C13.5854 3.76693 13.557 3.67674 13.5038 3.60118C13.4506 3.52566 13.3752 3.46854 13.2881 3.43775L5.66141 0.765878C5.59422 0.743119 5.52259 0.736636 5.45238 0.746958C5.3822 0.75728 5.31546 0.784112 5.25766 0.825253C5.19953 0.866023 5.15218 0.920268 5.11961 0.983345C5.08701 1.04642 5.07021 1.11645 5.07062 1.18744V5.99088H2.79953C2.68143 5.99088 2.56816 6.03778 2.48465 6.1213C2.40114 6.20481 2.35422 6.31809 2.35422 6.43619V17.3671H1.1875C1.0694 17.3671 0.956128 17.414 0.872617 17.4975C0.789106 17.5811 0.742188 17.6943 0.742188 17.8124C0.742188 17.9305 0.789106 18.0438 0.872617 18.1273C0.956128 18.2108 1.0694 18.2578 1.1875 18.2578H17.8125C17.9306 18.2578 18.0439 18.2108 18.1274 18.1273C18.2109 18.0438 18.2578 17.9305 18.2578 17.8124C18.2578 17.6943 18.2109 17.5811 18.1274 17.4975C18.0439 17.414 17.9306 17.3671 17.8125 17.3671ZM5.96125 1.81682L12.6944 4.174V9.05463H11.0052C10.8871 9.05463 10.7738 9.10153 10.6903 9.18505C10.6068 9.26856 10.5598 9.38184 10.5598 9.49994V17.3671H8.68062V6.43619C8.68062 6.31809 8.63372 6.20481 8.55021 6.1213C8.4667 6.03778 8.35341 5.99088 8.23531 5.99088H5.96125V1.81682ZM3.24484 6.8815H7.79V17.3671H3.24484V6.8815ZM11.4505 17.3671V9.94525H15.7552V17.3671H11.4505Z"
      fill="white"
    />
  </svg>
);
