// Libs
import Icon from '@ant-design/icons';

const icon = () => (
  <svg viewBox="0 0 247 130" xmlns="http://www.w3.org/2000/svg">
    <g fill="#bf0a30" fillRule="evenodd" fontSize="12">
      <path d="m0 0h247v10h-247z" />
      <path d="m0 20h247v10h-247z" />
      <path d="m0 40h247v10h-247z" />
      <path d="m0 60h247v10h-247z" />
      <path d="m0 80h247v10h-247z" />
      <path d="m0 100h247v10h-247z" />
      <path d="m0 120h247v10h-247z" />
    </g>
    <g fill="#fff" fillRule="evenodd" fontSize="12">
      <path d="m0 10h247v10h-247z" />
      <path d="m0 30h247v10h-247z" />
      <path d="m0 50h247v10h-247z" />
      <path d="m0 70h247v10h-247z" />
      <path d="m0 90h247v10h-247z" />
      <path d="m0 110h247v10h-247z" />
    </g>
    <path d="m0 0h98.8v70h-98.8z" fill="#002868" fillRule="evenodd" />
    <path
      transform="translate(8.2333 7)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(24.7,7)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(41.167 7)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(57.633 7)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(74.1,7)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(90.567 7)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(16.467 14)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(32.933 14)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(49.4,14)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(65.867 14)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(82.333 14)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <g transform="translate(0,14)" fill="#fff" fillRule="evenodd">
      <path
        transform="translate(8.2333 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(24.7,7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(41.167 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(57.633 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(74.1,7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(90.567 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(16.467 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(32.933 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(49.4,14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(65.867 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(82.333 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
    </g>
    <g transform="translate(0,28)" fill="#fff" fillRule="evenodd">
      <path
        transform="translate(8.2333 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(24.7,7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(41.167 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(57.633 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(74.1,7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(90.567 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(16.467 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(32.933 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(49.4,14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(65.867 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(82.333 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
    </g>
    <g transform="translate(0,42)" fill="#fff" fillRule="evenodd">
      <path
        transform="translate(8.2333 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(24.7,7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(41.167 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(57.633 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(74.1,7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(90.567 7)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(16.467 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(32.933 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(49.4,14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(65.867 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
      <path
        transform="translate(82.333 14)"
        d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      />
    </g>
    <path
      transform="translate(8.2333 63)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(24.7,63)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(41.167 63)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(57.633 63)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(74.1,63)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      transform="translate(90.567 63)"
      d="m0-4.004 0.89895 2.7667h2.9091l-2.3535 1.7099 0.89895 2.7667-2.3535-1.7099-2.3535 1.7099 0.89895-2.7667-2.3535-1.7099h2.9091l0.89895-2.7667z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default (props) => <Icon component={icon} {...props} />;
