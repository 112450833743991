export default () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58 56.4998H54.07V29.9998C54.07 29.602 53.912 29.2204 53.6307 28.9391C53.3494 28.6578 52.9678 28.4998 52.57 28.4998H43.76V10.9998C43.7612 10.6886 43.6656 10.3848 43.4864 10.1303C43.3072 9.8759 43.0534 9.6835 42.76 9.5798L17.07 0.579799C16.8437 0.503139 16.6024 0.481299 16.3659 0.516069C16.1295 0.550839 15.9047 0.641219 15.71 0.779799C15.5142 0.917129 15.3547 1.09985 15.245 1.31232C15.1352 1.52479 15.0786 1.76068 15.08 1.9998V18.1798H7.43C7.03218 18.1798 6.65064 18.3378 6.36934 18.6191C6.08804 18.9004 5.93 19.282 5.93 19.6798V56.4998H2C1.60218 56.4998 1.22064 56.6578 0.93934 56.9391C0.65804 57.2204 0.5 57.602 0.5 57.9998C0.5 58.3976 0.65804 58.7792 0.93934 59.0605C1.22064 59.3418 1.60218 59.4998 2 59.4998H58C58.3978 59.4998 58.7794 59.3418 59.0607 59.0605C59.342 58.7792 59.5 58.3976 59.5 57.9998C59.5 57.602 59.342 57.2204 59.0607 56.9391C58.7794 56.6578 58.3978 56.4998 58 56.4998ZM18.08 4.1198L40.76 12.0598V28.4998H35.07C34.6722 28.4998 34.2906 28.6578 34.0093 28.9391C33.728 29.2204 33.57 29.602 33.57 29.9998V56.4998H27.24V19.6798C27.24 19.282 27.082 18.9004 26.8007 18.6191C26.5194 18.3378 26.1378 18.1798 25.74 18.1798H18.08V4.1198ZM8.93 21.1798H24.24V56.4998H8.93V21.1798ZM36.57 56.4998V31.4998H51.07V56.4998H36.57Z"
      fill="black"
    />
  </svg>
);
