const Dashboard = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.4341 13.0988C32.6698 12.4651 31.9692 12.8763 31.5087 13.2412C29.1263 15.128 27.1193 16.6065 24.9681 18.235C22.6006 20.0273 20.1525 21.8808 17.5245 23.9829C15.5264 25.5812 15.062 27.7727 16.2499 29.9954C16.582 30.7209 17.3613 31.3641 18.3888 31.7605C18.8995 31.9631 19.4433 32.0691 19.9926 32.0732C21.4192 32.0732 22.7349 31.3058 23.7433 29.8416C25.7621 26.91 27.4681 24.3317 29.2743 21.602C30.6839 19.4717 31.9241 17.4743 33.5366 15.1C34.0501 14.3439 34.0108 13.5771 33.4341 13.0988Z"
      fill="#E9ECF0"
    />
    <path
      d="M20.0008 11.8987C22.0276 12.0765 23.9988 12.6554 25.7999 13.6017C26.0654 13.7413 26.3277 13.8901 26.5824 14.0466C26.8417 13.8425 27.1009 13.637 27.3587 13.4329C28.408 12.6014 29.4579 11.7709 30.5083 10.9414C29.6009 10.375 28.6486 9.88397 27.6609 9.47322C27.4707 9.39344 27.2789 9.31519 27.0856 9.24155C24.1035 8.09353 20.8869 7.68844 17.7134 8.06124C14.5398 8.43404 11.5046 9.57354 8.86979 11.3814C6.23497 13.1892 4.07967 15.6111 2.58992 18.438C1.10017 21.2648 0.320721 24.4118 0.318848 27.6072H2.08315C2.26208 25.2904 2.89822 23.0321 3.95484 20.9626C4.98142 18.9577 6.3944 17.1755 8.11245 15.7188C9.77535 14.3092 11.701 13.2429 13.7782 12.5814C15.7869 11.945 17.9018 11.713 20.0008 11.8987Z"
      fill="#3366CC"
    />
    <path
      d="M39.6811 27.6071C39.6811 27.4798 39.6795 27.3525 39.6764 27.2251C39.6806 27.1715 39.6816 27.1177 39.6795 27.064C39.6488 26.3 39.6013 25.5314 39.4954 24.7735C39.0488 21.5499 37.7984 18.4905 35.8594 15.8768L34.4234 17.9925C33.7821 18.9375 33.1368 19.8887 32.4873 20.846C32.6783 21.2982 32.8452 21.7601 32.9874 22.2298C33.5113 23.9709 33.686 25.7983 33.5014 27.6071H39.6811Z"
      fill="#3366CC"
    />
  </svg>
);

export default Dashboard;
