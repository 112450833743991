export default (props) => {
  return {
    layout: 'top',
    // contentWidth: 'Fluid',
    splitMenus: true,
    //   loading: true,
    siderMenuType: 'group',
    title: '',
    logo: null,
    prefixCls: 'sapphirebooking-admin',
    collapsedButtonRender: false,
    menu: {
      collapsedShowGroupTitle: false,
    },
    fixSiderbar: true,
    pageTitleRender: () => props.title + ' | SapphireBooking',
  };
};
