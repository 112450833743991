// Libs
import {
  ProConfigProvider,
  ProLayout,
  nanoid,
} from '@ant-design/pro-components';
import { Avatar, ConfigProvider, Dropdown, Menu, Space, theme } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiLockPasswordFill, RiLogoutBoxRFill } from 'react-icons/ri';
import { ImProfile } from 'react-icons/im';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useAuthUser, useSignOut } from 'react-auth-kit';

// Assets
import USAFlag from '@app/components/common/Icons/USAFlag';
import VietnamFlag from '@app/components/common/Icons/VietnamFlag';
import Bell from '@app/components/common/Admin/Icons/Bell';
import Letter from '@app/components/common/Admin/Icons/Letter';

// Settings
import defaultSettings from './_defaultSettings';
import defaultProps from './_defaultProps';

// Styles
import './SetupLayout.scss';

// Components
import CustomIcon from '@app/components/common/Icons/Index';
import Footer from '@app/components/common/Footer/Footer';

// Hooks
import useLanguage, { getLocalByLanguageCode } from '@app/hooks/useLanguage';

// Constants
import { LANGUAGE_CODE, ROUTES } from '@app/constants';

// Common variables
const cursorStyle = {
  cursor: 'pointer',
};

export default () => {
  // Using
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const [collapsed, setCollapsed] = useState(true);
  const [titlePage, setTitlePage] = useState('');
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const authUserData = useAuthUser();
  const user = authUserData();

  useEffect(() => {
    const getNameTilePage = () => {
      const titleNames = pathname.split('/');

      return titleNames
        .filter((name) => {
          return t(`pages.${name}.title`).includes('.') === false;
        })
        .map((name) => {
          return t(`pages.${name}.title`);
        })
        .join('-');
    };

    setTitlePage(getNameTilePage() || t('pages.setup.title'));
  }, [pathname]);

  const multiLanguages = useMemo(
    () => [
      {
        key: LANGUAGE_CODE.VIETNAMESE,
        label: <>{t('common.header.right.language.vietnamese')}</>,
        icon: <VietnamFlag style={{ width: '20px' }} />,
        onClick: () => setLanguage(LANGUAGE_CODE.VIETNAMESE),
      },
      {
        key: LANGUAGE_CODE.ENGLISH,
        label: <>{t('common.header.right.language.english')}</>,
        icon: <USAFlag style={{ width: '20px' }} />,
        onClick: () => setLanguage(LANGUAGE_CODE.ENGLISH),
      },
    ],
    [setLanguage, t]
  );

  if (typeof document === 'undefined') {
    return <div />;
  }

  // Memos
  const userLoginOptions = useMemo(() => [
    {
      key: nanoid(),
      label: (
        <Space>
          {!!user && user.avatar && <Avatar src={user.avatar} size="default" />}
          <span>
            {t('admin.header.welcome', {
              name: !!user && !!user.name && user.name.toUpperCase(),
            })}
          </span>
        </Space>
      ),
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          className="language"
          rootClassName="language-select"
          defaultSelectedKeys={[language]}
          items={[
            {
              key: nanoid(),
              label: t('admin.language.' + language),
              children: [
                {
                  key: LANGUAGE_CODE.ENGLISH,
                  label: t('admin.language.en'),
                  icon: <USAFlag style={{ width: '20px' }} />,
                  onClick: () => setLanguage(LANGUAGE_CODE.ENGLISH),
                },
                {
                  key: LANGUAGE_CODE.VIETNAMESE,
                  label: t('admin.language.vi'),
                  icon: <VietnamFlag style={{ width: '20px' }} />,
                  onClick: () => setLanguage(LANGUAGE_CODE.VIETNAMESE),
                },
                // {
                //   key: LANGUAGE_CODE.JAPANESE,
                //   label: t('admin.language.ja'),
                //   onClick: () => setLanguage(LANGUAGE_CODE.JAPANESE),
                // },
              ],
              expandIcon: <MdOutlineKeyboardArrowRight />,
            },
          ]}
        />
      ),
      icon:
        language === LANGUAGE_CODE.ENGLISH ? (
          <USAFlag style={{ width: '20px' }} />
        ) : (
          <VietnamFlag style={{ width: '20px' }} />
        ),
      danger: false,
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          items={[
            {
              key: nanoid(),
              label: t('admin.profile'),
              onClick: () => {
                setPathname('/user/profile');
                navigate('/user/profile');
              },
            },
          ]}
        />
      ),
      icon: <ImProfile size="20" />,
      danger: false,
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          items={[
            {
              key: nanoid(),
              label: t('admin.change-password'),
            },
          ]}
        />
      ),
      icon: <RiLockPasswordFill size="20" />,
      danger: false,
    },
    {
      key: nanoid(),
      label: (
        <Menu
          mode="vertical"
          items={[
            {
              key: nanoid(),
              label: t('admin.logout'),
              onClick: () => signOut(),
            },
          ]}
        />
      ),
      icon: <RiLogoutBoxRFill size="20" />,
      danger: true,
    },
  ]);

  // Rendering
  return (
    <div id="setup-layout">
      <ProConfigProvider>
        <ConfigProvider
          locale={getLocalByLanguageCode(language)}
          getTargetContainer={() => {
            return document.getElementById('setup-layout') || document.body;
          }}
          theme={{
            algorithm: theme.defaultAlgorithm,
            components: {
              Menu: {
                groupTitleColor: '#4B5254',
              },
              Layout: {
                footerBg: '#FFFFFF',
                bodyBg: '#FFFFFF',
              },
            },
          }}
        >
          <ProLayout
            {...defaultProps}
            // token={{
            //   pageContainer: {
            //     colorBgPageContainer: '#FFFFFF'
            //   }
            // }}
            {...defaultSettings({ title: titlePage })}
            avatarProps={{
              src: !!user && user.avatar,
              size: 'small',
              title: (!!user && !!user.name && user.name.toUpperCase()) || '',
              render: (_props, defaultDom) => (
                <Dropdown
                  trigger={['click']}
                  className="avatar"
                  rootClassName="root-avatar"
                  arrow
                  menu={{
                    items: userLoginOptions,
                    className: 'menu-avatar',
                  }}
                  icon={<MdOutlineKeyboardArrowDown />}
                >
                  <Space>
                    {defaultDom}
                    <MdOutlineKeyboardArrowDown />
                  </Space>
                </Dropdown>
              ),
            }}
            menuItemRender={(item, dom) =>
              !item.routes || item.routes.length === 0 ? (
                <div
                  onClick={() => {
                    setPathname(item.path || ROUTES.HOME);
                    navigate(item.path || ROUTES.HOME);
                  }}
                  style={{
                    ...cursorStyle,
                  }}
                >
                  {dom}
                </div>
              ) : (
                <div>{dom}</div>
              )
            }
            collapsed={collapsed}
            onCollapse={setCollapsed}
            location={{
              pathname,
            }}
            actionsRender={(props) => {
              if (props.isMobile) return [];
              if (typeof window === 'undefined') return [];
              return [
                <>
                  <div className="language">
                    <Dropdown
                      menu={{
                        defaultSelectedKeys: [language],
                        selectedKeys: [language],
                        items: multiLanguages,
                      }}
                      rootClassName="language-root"
                    >
                      <Space>
                        <CustomIcon name="language" />
                        <p>
                          {language === LANGUAGE_CODE.VIETNAMESE ? 'VN' : 'EN'}
                        </p>
                      </Space>
                    </Dropdown>
                  </div>
                </>,
                <Bell key="Bell" />,
                <Letter key="Letter" />,
              ];
            }}
            footerRender={() => <Footer />}
          >
            <Outlet />
          </ProLayout>
        </ConfigProvider>
      </ProConfigProvider>
    </div>
  );
};
