// Libs
import Icon from '@ant-design/icons';

const icon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_705_441)">
      <path
        d="M10.0799 20.58H10.9199C11.9338 20.58 12.7822 19.8572 12.9775 18.9H20.5799V16.1205L18.0599 14.8605V9.66004C18.0599 6.21604 15.7428 3.3088 12.5877 2.39992C12.5239 1.29868 11.6167 0.420044 10.4999 0.420044C9.38314 0.420044 8.47594 1.29868 8.4121 2.39992C5.25706 3.3088 2.93992 6.21604 2.93992 9.66004V14.8605L0.419922 16.1205V18.9H8.02234C8.21764 19.8572 9.06604 20.58 10.0799 20.58ZM10.9199 19.74H10.0799C9.53224 19.74 9.0652 19.3889 8.89174 18.9H12.1077C11.9346 19.3889 11.4676 19.74 10.9199 19.74ZM10.4999 1.26004C11.0862 1.26004 11.5755 1.66408 11.7158 2.20714C11.3189 2.14246 10.9149 2.10004 10.4999 2.10004C10.085 2.10004 9.68092 2.14246 9.28402 2.20714C9.4243 1.66408 9.9136 1.26004 10.4999 1.26004ZM1.25992 16.6396L3.77992 15.3796V9.66004C3.77992 5.9548 6.79468 2.94004 10.4999 2.94004C14.2052 2.94004 17.2199 5.9548 17.2199 9.66004V15.3796L19.7399 16.6396V18.06H13.0199H7.97992H1.25992V16.6396Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_705_441">
        <rect width="21" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default (props) => <Icon component={icon} {...props} />;
