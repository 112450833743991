// Libs
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { lazy } from 'react';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';

// Layouts
import AdminLayout from '@app/layouts/AdminLayout/AdminLayout';
import AdminAuthLayout from '@app/layouts/AdminAuthLayout/AdminAuthLayout';
import SetupLayout from '@app/layouts/SetupLayout/SetupLayout';

// Hocs
import withLoading from '@app/hocs/withLoading.hoc';

// Constants
import { PATH_NAME, ROUTES } from './constants';

// Pages
const RateAndAvailability = lazy(() =>
  import('@app/pages/RateAndAvailability/RateAndAvailability')
);
const AdminLogin = lazy(() => import('@app/pages/Member/Login/Login'));
const AdminSignUp = lazy(() => import('@app/pages/Member/SignUp/SignUp'));
const Calendar = lazy(() =>
  import('@app/pages/RateAndAvailability/Calendar/Calendar')
);
const Reports = lazy(() => import('@app/pages/Reports/Reports'));
const Members = lazy(() => import('@app/pages/Members/Members'));
const Reservations = lazy(() => import('@app/pages/Reservations/Reservations'));
const Promotions = lazy(() => import('@app/pages/Promotions/Promotions'));
const AddNewPromotion = lazy(() =>
  import('@app/pages/Promotions/AddNew/AddNew')
);
const Reviews = lazy(() => import('@app/pages/Reviews/Reviews'));
const Settings = lazy(() => import('@app/pages/Settings/Settings'));
const Users = lazy(() => import('@app/pages/Users/Users'));
const APIs = lazy(() => import('@app/pages/APIs/APIs'));
const Dashboard = lazy(() => import('@app/pages/Dashboard/Dashboard'));
const VerifyAccount = lazy(() =>
  import('@app/pages/Member/VerifyAccount/VerifyAccount')
);
const SetupProperty = lazy(() => import('@app/pages/Property/Setup/Setup'));
const NotFound = lazy(() => import('@app/pages/NotFound'));
const ConfirmationSignUp = lazy(() =>
  import('@app/pages/Member/Confirmation/Confirmation')
);
const ConfirmationSuccess = lazy(() =>
  import('@app/pages/Member/Confirmation/ConfirmationSuccess')
);
const ConfirmationFailure = lazy(() =>
  import('@app/pages/Member/Confirmation/ConfirmationFailure')
);

// Pages with loading
const AdminSignUpPage = withLoading(AdminSignUp);
const RateAndAvailabilityPage = withLoading(RateAndAvailability);
const AdminLoginPage = withLoading(AdminLogin);
const CalendarPage = withLoading(Calendar);
const ReportsPage = withLoading(Reports);
const MembersPage = withLoading(Members);
const ReservationsPage = withLoading(Reservations);
const PromotionsPage = withLoading(Promotions);
const AddNewPromotionPage = withLoading(AddNewPromotion);
const ReviewsPage = withLoading(Reviews);
const SettingsPage = withLoading(Settings);
const UsersPage = withLoading(Users);
const APIsPage = withLoading(APIs);
const DashboardPage = withLoading(Dashboard);
const NotFoundPage = withLoading(NotFound);
const VerifyAccountPage = withLoading(VerifyAccount);
const ConfirmationSignUpPage = withLoading(ConfirmationSignUp);
const ConfirmationSuccessPage = withLoading(ConfirmationSuccess);
const ConfirmationFailurePage = withLoading(ConfirmationFailure);
const SetupPropertyPage = withLoading(SetupProperty);

// Variables
const PrivateAdminRoute = ({ Component }) => {
  const isAuthenticated = useIsAuthenticated();
  const authUserData = useAuthUser();
  const user = authUserData();

  return isAuthenticated() && !!user && !!user.role ? (
    <Component />
  ) : (
    <Navigate
      to={['', PATH_NAME.MEMBER.INDEX, PATH_NAME.MEMBER.LOGIN].join('/')}
    />
  );
};

export const MainRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFoundPage />}>
      <Route path={PATH_NAME.NOT_FOUND} element={<AdminAuthLayout />}>
        <Route index element={<NotFoundPage />} />
      </Route>

      <Route path={PATH_NAME.MEMBER.INDEX} element={<AdminAuthLayout />}>
        <Route path={PATH_NAME.MEMBER.LOGIN} element={<AdminLoginPage />} />
        <Route path={PATH_NAME.MEMBER.SIGN_UP} element={<AdminSignUpPage />} />
        <Route path={PATH_NAME.MEMBER.VERIFY} element={<VerifyAccountPage />} />
        <Route
          path={PATH_NAME.MEMBER.CONFIRMATION}
          element={<ConfirmationSignUpPage />}
        />
        <Route
          path={PATH_NAME.MEMBER.CONFIRMATION_SUCCESS}
          element={<ConfirmationSuccessPage />}
        />
        <Route
          path={PATH_NAME.MEMBER.CONFIRMATION_FAILURE}
          element={<ConfirmationFailurePage />}
        />
      </Route>

      <Route
        path={ROUTES.HOME}
        element={<PrivateAdminRoute Component={AdminLayout} />}
      >
        <Route index path={PATH_NAME.HOME} element={<DashboardPage />} />
        <Route path={ROUTES.MAIN.CALENDAR} element={<CalendarPage />} />

        <Route
          path={PATH_NAME.RATE_AND_AVAILABILITY}
          element={<RateAndAvailabilityPage />}
        />

        <Route path={PATH_NAME.REPORTS} element={<ReportsPage />} />
        <Route path={PATH_NAME.MEMBERS} element={<MembersPage />} />
        <Route path={PATH_NAME.USERS} element={<UsersPage />} />
        <Route path={PATH_NAME.PROMOTIONS} element={<PromotionsPage />} />
        <Route
          path={ROUTES.MAIN.ADD_NEW_PROMOTION}
          element={<AddNewPromotionPage />}
        />
        <Route path={PATH_NAME.REVIEWS} element={<ReviewsPage />} />
        <Route path={PATH_NAME.RESERVATIONS} element={<ReservationsPage />} />
        <Route path={PATH_NAME.SETTINGS} element={<SettingsPage />} />
        <Route path={PATH_NAME.APIS} element={<APIsPage />} />
        <Route path={ROUTES.PROPERTY.SETUP} element={<SetupPropertyPage />} />
      </Route>
      <Route
        path={PATH_NAME.SETUP}
        element={<PrivateAdminRoute Component={SetupLayout} />}
      >
        <Route index element={<SetupPropertyPage />} />
      </Route>
    </Route>
  )
);
