const Chart = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M29.6307 12.1952H25.1626V19.0593H29.6307V12.1952Z" fill="white" />
    <path d="M22.2159 7.87512H17.7478V19.0593H22.2159V7.87512Z" fill="white" />
    <path d="M37.0545 2.7594H32.5864V19.0592H37.0545V2.7594Z" fill="white" />
    <path
      d="M17.8242 22.4228L22.5985 33.7783C23.8169 33.1997 24.9532 32.462 25.9776 31.5845L26.0105 31.5557C28.4553 29.4581 30.1609 26.6299 30.8754 23.4888L30.8877 23.4435C30.9659 23.1019 31.0318 22.7521 31.0853 22.4187L17.8242 22.4228ZM27.7021 25.238C27.3899 25.9474 27.0109 26.6254 26.5703 27.263C25.9494 28.1644 25.2148 28.9819 24.3848 29.6954L24.3642 29.716C24.1708 29.8806 23.9732 30.0411 23.7715 30.1934L21.5408 24.8923L27.8544 24.8882C27.805 25.0075 27.7556 25.1228 27.7021 25.238Z"
      fill="#3366CC"
    />
    <path
      d="M14.3753 21.5103C14.3572 21.4611 14.346 21.4296 14.3369 21.3982C14.3311 21.3779 14.3266 21.3593 14.3171 21.3164L14.3052 21.2593C14.3007 21.2342 14.2969 21.2096 14.2938 21.1824C14.291 21.162 14.289 21.1336 14.2868 21.0938L14.2837 20.931L15.1876 4.95068C14.3301 5.00293 13.4786 5.12686 12.6418 5.32118C10.6884 5.78019 8.8455 6.62242 7.22004 7.79903C5.59457 8.97565 4.21885 10.4632 3.17265 12.1755C2.12644 13.8879 1.43056 15.7908 1.12536 17.7741C0.82016 19.7574 0.911708 21.7815 1.3947 23.7292C1.87769 25.6768 2.74251 27.5092 3.93901 29.1201C5.13552 30.7309 6.63991 32.0883 8.36495 33.1133C10.09 34.1384 12.0014 34.8108 13.9883 35.0916C15.9751 35.3724 17.998 35.256 19.9396 34.7491L14.3753 21.5103Z"
      fill="#3366CC"
    />
  </svg>
);

export default Chart;
